import React, { ReactElement } from 'react'

import Template from '@/components/app/Template'
import { getPage } from '@/themes'
import { MenuPageProps } from '@/types/pages/menu'

/**
 * Menu page
 */
export default function Menu(props: MenuPageProps): ReactElement {
  const MenuPage = getPage<MenuPageProps>(`Menu`)
  return (
    <Template {...props}>
      <MenuPage {...props} />
    </Template>
  )
}
